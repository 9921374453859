<template>
    <div>
      <v-card height="300px">
        <v-toolbar
          height="20"
          color="#3cc"
        >
          <v-toolbar-title class="toolbar-title">地点情報</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            size="20"
            @click="this.$emit('invisibleInfoWindow');"
          >
            <v-icon icon="mdi-close" size="20"></v-icon>
          </v-btn>
        </v-toolbar>
        <div class="school-change">
          <v-btn
            flat
            v-if="schoolType == 'jhs'"
            class="hidden-content"
            @click="this.$emit('changeSchool');"
            size="small"
          >小学校情報を表示する</v-btn>
          <v-btn
            flat
            v-if="schoolType == 'es'"
            class="hidden-content"
            @click="this.$emit('changeSchool');"
            size="small"
          >中学校情報を表示する</v-btn>
        </div>
        <v-tabs
          v-model="tab"
          height="24px"
          @update:modelValue="onTabChange"
          bg-color="#ffffff"
          class="tab-header"
        >
          <v-tab
            value="assign"
            height="23px"
            class="tab-nonactive"
            selected-class="tab-active"
          >指定学区情報</v-tab>
          <v-tab
            value="select"
            height="23px"
            class="tab-nonactive"
            selected-class="tab-active"
            v-if="selectSchoolList.length != 0"
          >学校選択制情報({{ selectSchoolList.length }})</v-tab>
        </v-tabs>

        <v-card-text class="tab-content">
          <v-window v-model="tab">
            <v-window-item value="assign">
              <div class="assign-school-content">
                <div v-for="(assignShool, index) in assignSchoolList" :key="assignShool">
                  <v-sheet 
                    class="school-data-sheet"
                    :style="[index%2 == 0 ? {'background-color': '#F5F5F5'}: {'background-color': '#FFFFFF'}]"
                  >
                    <div class="school-data">
                      <v-row 
                        dense
                        class="row-1"
                      >
                        <v-col>
                            学校名：{{ assignShool.schoolName }}
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                        class="row-1"
                      >
                        <v-col>
                            所在地：{{ assignShool.schoolAddress }}
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </div>
              </div>
              <div class="assign-school-notice">
                <div>
                  <p class="notice-text">※通学区域や就学校の最新情報および各種制度の詳細情報については、自治体または教育委員会へお問い合わせください。</p>
                  <p class="notice-text" v-if="assignSchoolList.length > 1">※指定校が複数表示される場合は、複数校が対象または学区範囲が不確実な地域です。</p>
                </div>
              </div>
            </v-window-item>

            <v-window-item value="select">
              <div class="select-school-content">
                <div v-for="(selectSchool, index) in selectSchoolList" :key="selectSchool">
                  <v-sheet 
                    class="school-data-sheet"
                    v-on:mouseover="mouseOverAction(selectSchool)" v-on:mouseleave="mouseLeaveAction"
                    :style="[index%2 == 0 ? {'background-color': '#F5F5F5'}: {'background-color': '#FFFFFF'}]"
                  >
                    <div class="school-data" v-if="selectSchool.schoolcode != null">
                      <v-row 
                        dense
                        class="row-title"
                      >
                        <v-col class="select-school-title">
                            {{selectSchool.sys_name}}（対象校：{{selectSchool.index}}）
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                        class="row-text"
                      >
                        <v-col>
                            対象学校：{{ selectSchool.schoolname }}
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                        class="row-text"
                      >
                        <v-col>
                            住所：{{ selectSchool.address }}
                        </v-col>
                      </v-row>
                    </div>
                    <div class="school-data" v-if="selectSchool.schoolcode == null">
                      <v-row 
                        dense
                        class="row-title"
                      >
                        <v-col class="select-school-title">
                            {{ selectSchool.sys_name }}（制度・条件情報のみ）
                        </v-col>
                      </v-row>
                      <v-row
                        dense
                        class="row-text"
                      >
                        <v-col>
                            {{ getJoken(selectSchool) }}
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </div>
              </div>
              <div class="select-school-notice">
                <p>※この地域では学校選択制が利用できる可能性があります。&nbsp;※表示している学校への就学には定員・対象年度・通学距離などの条件が定められている場合があります。&nbsp;※制度等に関する最新情報や詳細については、自治体または教育委員会へお問い合わせください。</p>
              </div>
            </v-window-item>

          </v-window>
        </v-card-text>
      </v-card>
      <div class="anchor"></div>
    </div>
</template>
  
<script>
  import axios from 'axios'
  axios.defaults.withCredentials = true;
  export default {
    name: "infowindow",
    props: ["map", "assignSchoolList", "selectSchoolList", "bounds", "schoolType"],
    emits: ['changeEmphasis', 'invisibleInfoWindow', 'changeSchool', 'fitBoundsInfoWindow'],
    data() {
      return {
        tab: "assign",
        hoverPoint: null,
        selectPointList: [],
        selectInfoList: [],
        isVisible: true,
        clickedSchoolList: [],
        clickedSelectTab: false,
      }
    },
    mounted() {
      // console.log(this.selectSchoolList);
      // 吹き出しを含めたzoomレベルの調整
      this.$emit('fitBoundsInfoWindow', this.bounds);
      this.$emit('fitBoundsInfoWindow', this.bounds);
    },
    beforeUnmount() {
      // console.log(this);
      this.clearSelectSchool();
    },
    methods: {
      mouseOverAction(school){
        if (school.schoolcode == null) return;
        const markerTag = this.createMarkerDom(school.index, true);

        this.hoverPoint = new window.google.maps.marker.AdvancedMarkerElement({
          position: {
            lat: school.coordinates[1],
            lng: school.coordinates[0],
          },
          map: this.map,
          content: markerTag,
        });
        this.hoverPoint.setMap(this.map);
      },
      mouseLeaveAction(){
        if (this.hoverPoint === null) return;
        this.hoverPoint.setMap(null);
        this.hoverPoint = null;
      },
      getJoken(selectSchool) {
        const joken = (selectSchool.jouken1 == null ? "" : selectSchool.jouken1)
           + (selectSchool.jouken2 == null ? "" : selectSchool.jouken2)
           + (selectSchool.jouken3 == null ? "" : selectSchool.jouken3)
        return joken;
      },
      onTabChange(value) {
        if (value == 'assign') {
          this.clearSelectSchool();
          this.$emit('changeEmphasis', true);
          this.changeFitBounds(this.bounds);
          this.$emit('fitBoundsInfoWindow', this.bounds);
          this.$emit('fitBoundsInfoWindow', this.bounds);
        } else if (value == 'select') {
          this.dispSelectSchool();
          this.$emit('changeEmphasis', false);

          if (!this.clickedSelectTab) {
            const schoolCode = this.assignSchoolList.reduce(
              (accumulator, currentValue) => {
                if (accumulator == '') {
                  return currentValue.schoolCode;
                } else if (accumulator > currentValue.schoolCode) {
                  return currentValue.schoolCode;
                } else {
                  return accumulator;
                }
              },
              '',
            );
            const sysName = this.selectSchoolList.reduce(
              (accumulator, currentValue) => {
                if (currentValue.sys_name != null) {
                  if (accumulator == '') {
                    return currentValue.sys_name;
                  } else {
                    let checkList = accumulator.split(',');
                    if (checkList.includes(currentValue.sys_name)) {
                      return accumulator;
                    } else {
                      return accumulator + ',' + currentValue.sys_name;
                    }
                  }
                } else {
                  return accumulator;
                }
              },
              '',
            );
            this.sendLog(0, schoolCode, sysName);
            this.clickedSelectTab = true;
          }
        }
      },
      clearSelectSchool() {
        for (let i = this.selectPointList.length-1; i >= 0; i--) {
          this.selectPointList[i].setMap(null);
          this.selectPointList.pop();
        }
        for (let i = this.selectInfoList.length-1; i >= 0; i--) {
          this.selectInfoList[i].close();
          this.selectInfoList.pop();
        }
      },
      createMarkerDom(index, mouseOver) {
        const markerTag = document.createElement("div");
        markerTag.textContent = String(index);
        if (mouseOver) {
          markerTag.style.backgroundColor = '#0000ff';
        } else {
          markerTag.style.backgroundColor = '#ffcc00';
        }
        markerTag.style.width = '40px';
        markerTag.style.height = '40px';
        markerTag.style.textAlign = 'center';
        markerTag.style.lineHeight = '38px';
        markerTag.style.borderRadius = '30px';
        markerTag.style.border = 'solid 3px';
        markerTag.style.transform = 'translate(-20px, -20px)';
        markerTag.style.fontFamily = 'メイリオ,Meiryo,ヒラギノ角ゴ Pro,Hiragino Kaku Gothic Pro,Verdana,Arial,sans-serif,ＭＳ Ｐゴシック';
        markerTag.style.fontSize = '20px';
        markerTag.style.color = '#ffffff';
        markerTag.style.position = 'absolute';

        return markerTag;
      },
      dispSelectSchool() {
        this.clearSelectSchool();

        let fitBounds = [[0, 0], [0, 0]];
        let max_lat = 0.0, max_lon = 0.0;
        let min_lat = 200.0, min_lon = 200.0;

        for (let i = 0; i < this.selectSchoolList.length; i++) {
          if ('coordinates' in this.selectSchoolList[i]) {
            const markerTag = this.createMarkerDom(this.selectSchoolList[i].index, false);

            const position = {
              lat: this.selectSchoolList[i].coordinates[1],
              lng: this.selectSchoolList[i].coordinates[0],
            };

            const selectSchoolPoint = new window.google.maps.marker.AdvancedMarkerElement({
              position: position,
              map: this.map,
              content: markerTag,
            });
            
            const infoWindow = new window.google.maps.InfoWindow();
            selectSchoolPoint.addListener("click", () => {
              infoWindow.close();
              infoWindow.setHeaderContent(this.selectSchoolList[i].schoolname);
              infoWindow.setContent(this.selectSchoolList[i].address);
              infoWindow.setPosition(position);
              infoWindow.open(selectSchoolPoint.map);

              // 追加のログ取得用ロジック
              if (this.clickedSchoolList.indexOf(this.selectSchoolList[i].schoolcode) == -1) {

                const schoolCode = this.assignSchoolList.reduce(
                  (accumulator, currentValue) => {
                    if (accumulator == '') {
                      return currentValue.schoolCode;
                    } else if (accumulator > currentValue.schoolCode) {
                      return currentValue.schoolCode;
                    } else {
                      return accumulator;
                    }
                  },
                  '',
                );
                const sysName = this.selectSchoolList[i].sys_name;
                this.sendLog(1, schoolCode, sysName, this.selectSchoolList[i].schoolcode);
                this.clickedSchoolList.push(this.selectSchoolList[i].schoolcode);
              }
            });

            selectSchoolPoint.setMap(this.map);
            this.selectPointList.push(selectSchoolPoint);
            this.selectInfoList.push(infoWindow);

            // fitBounds update
            if (max_lat < position.lat) {
              max_lat = position.lat;
            }
            if (min_lat > position.lat) {
              min_lat = position.lat;
            }
            if (max_lon < position.lng) {
              max_lon = position.lng;
            }
            if (min_lon > position.lng) {
              min_lon = position.lng;
            }
          }
        }
        fitBounds[0][1] = min_lat;
        fitBounds[1][1] = max_lat;
        fitBounds[0][0] = min_lon;
        fitBounds[1][0] = max_lon;
 
        if (max_lat == 0.0 && max_lon == 0.0 && min_lat == 200.0 && min_lon == 200.0) {
          return;
        }
        // 吹き出しを除いたzoomレベルの調整
        this.changeFitBounds(fitBounds)

        // 吹き出しを含めたzoomレベルの調整
        this.$emit('fitBoundsInfoWindow', fitBounds);
        this.$emit('fitBoundsInfoWindow', fitBounds);
      },
      changeFitBounds(bounds) {
        let nextBounds = new window.google.maps.LatLngBounds();
        nextBounds.extend(new window.google.maps.LatLng(
          bounds[0][1], 
          bounds[0][0]));
        nextBounds.extend(new window.google.maps.LatLng(
          bounds[1][1],
          bounds[1][0]));
        this.map.fitBounds(nextBounds);
      },
      sendLog(kbn, schoolCode, sysName, clickedSchoolCode = '') {
        let reqParam = '?';
        reqParam += 'kbn=' + kbn;
        reqParam += '&school=' + this.schoolType;
        reqParam += '&schoolCode=' + schoolCode;
        reqParam += '&sysName=' + sysName;
        if (clickedSchoolCode != '') {
          reqParam += '&clickedSchoolCode=' + clickedSchoolCode;
        }
        
        axios.get('gakku/selectlog' + reqParam)
          .then(response => {
            if (response.status != 200) {
              // this.openApiError(`しばらく待ってから再度お試しください`);
            }
          })
          .catch(function() {
            // this.openApiError(`しばらく待ってから再度お試しください`);
          }.bind(this));
      }
    },
  };
</script>

<style scoped>
.toolbar-title {
  font-size: 1rem;
  color: #ffffff;
}
.anchor {
  -webkit-filter: drop-shadow(0 4px 2px rgba(178, 178, 178, .4));
  filter: drop-shadow(0 4px 2px rgba(178, 178, 178, .4));
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translate(-50%, -10%);
  background: #fff;
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  content: "";
  height: 12px;
  left: 50%;
  position: absolute;
  top: 100%;
  width: 25px;
}

.school-change {
  display: flex;
  justify-content: flex-end;
}

.tab-content {
  overflow-y: auto;
  height: 226px;
  padding: 8px;
}

.assign-school-content {
  overflow-y: auto;
  height: 150px;
  font-size: 12px;
}

.assign-school-notice {
  height: 60px;
  font-size: 11px;
  color: #ff0000;
  display: flex;
  align-items: flex-end;
}

.select-school-content {
  overflow-y: auto;
  height: 165px;
  font-size: 12px;
}

.select-school-notice {
  height: 45px;
  font-size: 11px;
  color: #ff0000;
}

.tab-header {
  background-color: #ffffff;
  border-bottom: 4.5px solid #70cccc;
}

.tab-nonactive {
  height: 24px;
  background-color: #c0ffff;
  color: #000000;
}

.tab-active {
  background-color: #70cccc;
  color: #ffffff;
}

.notice-text {
  width: 100%;
}

.row-title {
  pointer-events: none;
}

.row-text {
  pointer-events: none;
  margin-top: 0px;
}

.school-data-sheet {
  padding: 0px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.school-data {
  padding: 4px;
}

.select-school-title {
  font-weight: bold;
}

div::-webkit-scrollbar {
    width: 5px;
}
div::-webkit-scrollbar-track {
   background: #F5F5F5;
}

div::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius:5px;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #E0E0E0 #F5F5F5;
}
</style>